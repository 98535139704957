import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label0", "label1", "label2"];

  initialize() {
    let labels = document.querySelectorAll(".form-check-label");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  showAdditionalFields0() {
    let field = document.getElementById("message");
    field.classList.add("d-flex");
    field.classList.remove("d-none");
    field.lastChild.innerHTML =
      'Ao selecionar esta opção você irá <span class="fw-bold">cadastrar</span> seus produtos direto na plataforma';
    this.label0Target.classList.add("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
  }

  showAdditionalFields1() {
    let field = document.getElementById("message");
    field.classList.add("d-flex");
    field.classList.remove("d-none");
    field.lastChild.innerHTML =
      'Ao selecionar esta opção você irá <span class="fw-bold">integrar</span> a sua loja do Shopify a plataforma';

    this.label0Target.classList.remove("active");
    this.label1Target.classList.add("active");
    this.label2Target.classList.remove("active");
  }

  showAdditionalFields2() {
    let field = document.getElementById("message");
    field.classList.add("d-flex");
    field.classList.remove("d-none");
    field.lastChild.innerHTML =
      'Ao selecionar esta opção você irá <span class="fw-bold">integrar</span> a sua loja do Shopify a plataforma';

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.add("active");
  }
}
