import "./components";
import "./initializers";
$(document).ready(function () {
  $("#state_input_address").change(function () {
    let id_state = $("#state_input_address").val();
    $.get("/list/cities/" + id_state, function (data) {
      let x;
      $("#city").html("");
      $("#city").append("<option value> Selecione a cidade </option >");
      for (x in data) {
        let option =
          '<option value ="' + data[x].id + '">' + data[x].name + "</option >";
        $("#city").append(option);
      }
    });
  });

  $(".state_search").change(function () {
    let id_state = $(".state_search").val();
    $.get("/list/cities/" + id_state, function (data) {
      let x;
      $("#city").html("");
      $("#city").append("<option value> Selecione a cidade </option >");
      for (x in data) {
        let option =
          '<option value ="' + data[x].id + '">' + data[x].name + "</option >";
        $("#city").append(option);
      }
    });
  });

  $(".cep_search").keyup(function () {
    const cep = $(this).val();
    const filter_cep = cep.replace(/\D/g, "");
    $(this).css({ "border-color": "rgb(206, 212, 218)" });
    $(".cep_error").remove();
    if (filter_cep.length == 8) {
      $.get(
        `https://viacep.com.br/ws/${filter_cep}/json/`,
        {},
        function (data) {
          if (!data.erro) {
            // CEP behavior on Checkout
            $("#address_zip_code_valid").val(true);
            setTimeout(() => {
              $(".form_address").removeClass("d-none");
              $("#address_zip_code").addClass("is-valid");
            }, 700);
            setTimeout(() => {
              $(".load_delivery").removeClass("d-none");
            }, 700);
            //End CEP behavior

            $("#customer_addresses_attributes_0_zip_code_valid").val(true);
            $(".neighborhood_search").val(data.bairro);
            $(".street_search").val(data.logradouro);
            let state_id = $(
              `.state_search option:contains(- ${data.uf})`
            ).val();
            $(".state_search").val(state_id);
            $(".state_search").css("pointer-events", "none");
            $(".state_search").css("touch-action", "none");
            $(".city_search").val("");
            $(".city_search").html("");
            $.get(`/list/cities/${state_id}`, {}, function (cities_data) {
              $(".city_search").append(
                '<option value="">Selecione a Cidade</option>'
              );
              cities_data.forEach(function (city) {
                var newOption = new Option(city.name, city.id, false, false);
                // Append it to the select
                $(".city_search").append(newOption);
                if (city.name == data.localidade) {
                  $(".city_search").val(city.id);
                }
              });
              // $(".city_search").css("pointer-events", "none");
              // $(".city_search").css("touch-action", "none");
            });

            $.get(
              "/payments/delivery_search/" + cep,
              {},
              function (data) {
                // console.log(data);
                if (data != 0) {
                  // $(".load_delivery").html('');
                  // $(".load_delivery").html('');
                } else {
                  // console.log("cep error");
                }
              },
              "script"
            );
          } else if ($(".cep_search").val().length == 8) {
            // $("#address_zip_code_valid").prop("checked", false);
            $("#address_zip_code_valid").val(false);
            $("#customer_addresses_attributes_0_zip_code_valid").val(false);
            //cep não encontrado.
            limpa_formulario_cep();
            $(".invalid-feedback").remove();
            //$(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });
            $("#cep_div").append(
              "<span class='cep_error small text-danger'>CEP não encontrado. </span>"
            );
            $(".form_address").removeClass("d-none");
            $(".load_delivery").removeClass("d-none");
            // $(".state_search").css("pointer-events", "auto");
            // $(".city_search").css("pointer-events", "auto");
            // $(".state_search").css("touch-action", "auto");
            // $(".city_search").css("touch-action", "auto");
            $.get(
              "/payments/delivery_search/" + cep,
              {},
              function (data) {
                // console.log(data);
                if (data != 0) {
                  // $(".load_delivery").html('');
                  // $(".load_delivery").html('');
                } else {
                  // console.log("cep error");
                }
              },
              "script"
            );
          }
        }
      );
    } else if ($(".cep_search").val().length >= 8) {
      //cep is invalid.
      $(".load_delivery").addClass("d-none");
      $(".form_address").addClass("d-none");
      $("#address_zip_code").removeClass("is-valid");

      limpa_formulario_cep();
      $(".invalid-feedback").remove();
      // $(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });

      $("#cep_div").append(
        "<span class='cep_error small text-danger'>CEP não encontrado. </span>"
      );
      $(".form_address").removeClass("d-none");
      $(".load_delivery").removeClass("d-none");
      // $("#address_zip_code_valid").prop("checked", false);
      $("#address_zip_code_valid").val(false);
      $("#customer_addresses_attributes_0_zip_code_valid").val(false);
      $(".state_search").css("pointer-events", "auto");
      $(".city_search").css("pointer-events", "auto");
      $(".state_search").css("touch-action", "auto");
      $(".city_search").css("touch-action", "auto");
      $.get(
        "/payments/delivery_search/" + cep,
        {},
        function (data) {
          // console.log(data);
          if (data != 0) {
            // $(".load_delivery").html('');
            // $(".load_delivery").html('');
          } else {
            // console.log("cep error");
          }
        },
        "script"
      );
    }
  });

  window.copyBarcode = () => {
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val($("#barcode").text()).select();
    document.execCommand("copy");
    $(".copyBarcodeLink").html("Copiado!");

    $temp.remove();
  };

  $(".copy-button").on("click", function () {
    var textArea = document.createElement("textarea");
    textArea.value = $(this).attr("data-link");
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    $(this).html("Link Copiado");
    textArea.remove();
    setTimeout(() => {
      $(this).html("Copiar Link");
    }, 2000);
  });
});
