import consumer from "./consumer";

const elementStartSocket = document.querySelector("#start_websocket");

if (elementStartSocket) {
  consumer.subscriptions.create(
    {
      channel: "OrderChannel",
      order_id: elementStartSocket.dataset.id,
    },
    {
      connected() { },

      received(data) {
        console.log("TESTE");
        console.log(data);
        const data_order_status = data;
        console.log("data_order: ", data_order_status)
        if (data?.success) {
          const payment_message = document.querySelector("#payment_info");
          const payment_message_description = document.querySelector(
            "#payment_info_description"
          );
          const container_payment =
            document.querySelector("#container-payment");
          if (data['payment_method'] == 'credit_card') {
            if (data['status_order'] == 'payment_approved') {
              payment_message.innerHTML = "Pagamento confirmado!";
              payment_message.classList.remove("text-warning");
              payment_message.classList.add("text-success");
            } else if (data['status_order'] == 'canceled' || data['status_order'] == 'recusado') {
              payment_message.innerHTML = "Pagamento recusado!";
              payment_message.classList.remove("text-warning");
              payment_message.classList.add("text-danger");
              document.getElementById("message_waiting_confirmation_payment").innerHTML = "";
            }
          }
          else {
            payment_message.innerHTML = "Pagamento confirmado";
            window.location.reload();
            payment_message.classList.remove("text-warning");
            payment_message.classList.add("text-success");
          }
          payment_message_description.classList.add("d-none");
          container_payment.classList.add("d-none");

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.facebook_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.taboola_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.tiktok_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.kwai_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.google_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.gtm_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          // Aqui recebe os data do server side
          console.log("RECENDO data", data);

          this.perform("pixel_sended", {
            order_id: elementStartSocket.dataset.id,
          });

          // Redirecionamento pagamento via PIX
          const checkoutContainer =
            document.getElementById("checkoutContainer");
          const checkoutRedirection = document.getElementById(
            "checkoutRedirection"
          );

          if (
            checkoutContainer &&
            checkoutRedirection &&
            checkoutContainer.dataset.pixRedirectUrl != ""
          ) {
            $("#checkoutRedirection").removeClass('d-none');
            $("#payment_info_description").addClass("d-none");
            $("#checkoutRedirection .timer").removeClass("d-none");

            var time = checkoutRedirection.dataset.redirectTimer;

            setInterval(function () {
              var seconds = time % 60;
              var minutes = (time - seconds) / 60;
              if (seconds.toString().length == 1) {
                seconds = "0" + seconds;
              }
              if (minutes.toString().length == 1) {
                minutes = "0" + minutes;
              }
              document.getElementById("time").innerHTML =
                minutes + ":" + seconds;
              time--;
              if (time == 0) {
                window.location.href = checkoutContainer.dataset.pixRedirectUrl;
              }
            }, 1000);
          }
        }
      },
    }
  );
}
